h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #1a1a1a;
}
h1,
.h1 {
  font-size: 2rem;
}
h2,
.h2 {
  font-size: 1.75rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1.25rem;
}
h5,
.h5 {
  font-size: 1rem;
}
h6,
.h6 {
  font-size: 0.75rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  letter-spacing: 3px;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: white;
  padding-top: 12px;
}

.text-space {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

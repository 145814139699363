/* You can add global styles to this file, and also import other style files */
@import './styles/container.scss';
@import './styles/typography.scss';
@import './styles/links.scss';
@import './styles/items.scss';
@import './styles/grid.scss';
@import './styles/buttons.scss';
@import './styles/spinner.scss';
/* Your styles goes here. */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&display=swap');

.img-fluid {
  max-width: 100%;
  height: auto;
}
img {
  vertical-align: middle;
  border-style: none;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #55595c;
  text-align: left;
  background-color: #fff;
  font-weight: 200;
  letter-spacing: 1px;
}
[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
main {
  min-height: 80vh;
  background-color: #f6f6f6;
}

.text_more {
  font-weight: bold;
  color: green;
}

/* unvisited link */
.menu a:link {
  color: #b8b7ad;
}

/* visited link */
.menu a:visited {
  color: #b8b7ad;
}

/* mouse over link */
.menu a:hover {
  color: #fff;
}

/* selected link */
.menu a:active {
  color: #b8b7ad;
}

input:not([type='submit']) {
  background: white !important;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
}
.carousel a {
  margin: 0 auto;
}

/* CARD ITEM */

.card_container {
  display: flex;
  padding: 15px;
  max-width: 100%;
  background: white;
  cursor: pointer;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  transition: all 0.3s ease-out;
  transform: translateZ(0);
  margin-bottom: 15px;
}

.card_container:hover {
  box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
    rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
    rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
    rgba(35, 35, 35, 0.05) 0px 64px 64px;
  transform: translate(0, -4px);
}

.card_text {
  padding-left: 15px;
}

.card_text_title {
  font-weight: bold;
  color: black;
}

.card_text_merch {
  font-weight: bold;
  color: green;
  text-decoration: underline;
}
.card_text_desc {
  margin-top: 5px;
}

.card_text_more {
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-weight: bold;
  color: green;
}

.card_text_date {
  position: absolute;
  bottom: 15px;
  left: 230px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .card_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 450px;
  }

  .card_image {
    margin-bottom: 15px;
  }

  .card_text_date {
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-weight: bold;
  }
}

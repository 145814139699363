.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-side {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-md-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

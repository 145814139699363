a {
  color: #1a1a1a;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: black;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

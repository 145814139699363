.btn {
  display: inline-block;
  font-weight: 600;
  color: #55595c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0;
  font-size: 0.765625rem;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &__light {
    color: #1a1a1a;
    background-color: #fff;
    border-color: #fff;
  }
}
